import taskGroupsEs419 from '../../apps/task-groups/i18n/languages/es-419';
import MessageMap from '../i18n.d';

const es419: MessageMap = {
  title: {
    pageTasks: 'Tareas',
    filterPhotoTasks: 'Tareas de foto',
    filterSimpleSurveyTasks: 'Tarea estándar y encuesta',
    filterSponsoredTasks: 'Tareas Patrocinadas',
    modalSubmissionForm: 'Enviar tarea',
    modalSendRecommendedTask: 'Enviar tarea recomendada',
    pageTaskCreateSimple: 'Nueva tarea estándar',
    pageTaskUpdateSimple: 'Actualizar tarea estándar',
    pageTaskDuplicateSimple: 'Duplicación de tarea estándar',
    pageTaskCreateSurvey: 'Nueva encuesta',
    pageTaskUpdateSurvey: 'Actualizar encuesta',
    pageTaskDuplicateSurvey: 'Duplicación de encuestas',
    pageTaskCreatePhoto: 'Nueva foto',
    pageTaskUpdatePhoto: 'Actualizar foto',
    pageTaskDuplicatePhoto: 'Duplicación de foto',
    pageTaskRecommendCreate: 'Crear template recomendado',
    pageTaskRecommendUpdate: 'Actualizar template recomendado',
    pageTaskRecommendDuplicate: 'Duplicación de template recomendado',
    pageNotFound: 'Página no encontrada',
    pageLoading: 'Cargando..',
    pageError: 'Algo salió mal',
    modalDeleteTask: '¿Borrar esta tarea: {taskName}?',
    modalDeleteSubmission: '¿Borrar este envío: {submissionName}?',
    modalConfirmDefault: 'Confirmación',
    mandatory: 'Obligatoria?',
    formTask: 'General',
    formTaskSub: 'Definir el contenido de la tarea y el patrocinio',
    formValidation: 'Condiciones de validación',
    effectivenessCheck: 'Condiciones de Validación',
    modalSaveDraft: '¿Guardar como borrador?',
  },
  subtitle: {
    modalSubmissionForm: 'Defina el nombre del envío y su período',
    coverageItemCard: 'SKU ID: {skuId}',
  },
  table: {
    header: {
      taskSubmissions: 'Envíos de tareas',
      taskSubmissionStatus: 'Status',
      taskSubmissionValidity: 'Validez',
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Gestión de tareas',
      pageOperations: 'Panel de operaciones',
      pageTaskGroups: 'Grupos de tareas',
      pageAudienceBuilder: 'Creador de audiencia',
    },
  },
  tooltip: {
    default: 'Ver este consejo',
    menu: 'Menu',
    expandTask: 'Expandir tarea',
    noPermission: 'No tiene permiso',
    submissionActives: 'Templates con envíos activos no se pueden eliminar',
    categoryDeprecated: 'La categoría de tarea seleccionada ya no está disponible. Seleccione otra',
    localModelIdDeprecated:
      'El ID de modelo local seleccionada ya no está disponible. Seleccione otro',
    task: {
      prioritize: 'Prioriza esta tarea para enviarla',
      globalTask:
        'Utilice esta etiqueta si esta tarea está relacionada con la estrategia global de BEES.',
      category: {
        disabled: '{profile} no puede realizar tareas de {category}',
      },
      subCategory: {
        disabled: '{subCategory} no está disponible',
        disabledInCategory: '{subCategory} no está disponible en {category}',
      },
      simple: {
        effectivenessCheck: {
          categoryNeeded:
            'Es necesario seleccionar una categoría antes de agregar el criterio de eficacia',
          subCategoryDisabled: '{subCategory} solo disponible en {categories}',
          addId: 'Use Enter para agregar ID o Comas para separar valores',
          addIdManualOrCsv:
            'Use Enter para agregar ID o Coma para separar valores o envíe un CSV. Para verificar cómo se construye el archivo, descargue el modelo a continuación',
        },
      },
      sponsored: 'Las tareas patrocinadas deben tener un socio patrocinador definido',
      survey: {
        reorder: 'Reordenar',
      },
      photo: {
        addVisionJobIdButton: 'Introduzca o pegue un Vision Job ID para continuar',
        visionJobIdSelected:
          'Vision Job ID ya seleccionado, para añadir otro, borre el Vision Job ID actual',
      },
    },
    submission: {
      delete: 'Cancelar envío',
      downloadCsv: 'Descargar lista de PDV',
      csvFirst:
        'Puedes generar la lista de usuarios de cuenta utilizando la base de datos local o completando la lista manualmente con los usuarios de cuenta en una planilla.',
      csvSecond:
        'Luego, inserta el archivo CSV aquí. Para saber cómo se hace el archivo, descarga el modelo abajo.',
      validityFirst:
        'La vigencia es el período en el que la tarea está habilitada por los BDRs para ser respondida/ejecutada.',
      validitySecond:
        'Si la tarea tiene una duración de sólo un día, debes seleccionar solamente la fecha de inicio. ',
    },
    priorization: {
      needChange: 'Cambiar la priorización para confirmar',
      confirm: 'Confirmar',
      reorder: 'Reordenar',
      cancel: 'Cancelar',
    },
    taskForm: {
      controlSettings: 'Editar perfil y Operaciones en la página de gestión de tareas',
    },
  },
  popper: {
    task: {
      duplicate: {
        reminder: {
          title: 'Recuerda:',
          message: 'Cambia el título del la tarea para poder diferenciarlo del que duplicaste.',
        },
      },
      viewOnly: {
        reminder: {
          title: 'Recuerda:',
          message: 'Esta página es solo para ver y los datos no se pueden cambiar.',
        },
      },
    },
    submission: {
      create: {
        validity:
          'La vigencia es el período en el que la tarea está habilitada por los BDRs para ser respondida/ejecutada. Si la tarea tiene una duración de sólo un día, debes seleccionar solamente la fecha de inicio. ',
        dropzoneFile:
          'Puedes generar la lista de usuarios de cuenta utilizando la base de datos local o completando la lista manualmente con los usuarios de cuenta en una planilla. Luego, inserta el archivo CSV aquí. Para saber cómo se hace el archivo, descarga el modelo abajo.',
      },
    },
  },
  toast: {
    success: {
      task: {
        create: 'Nueva tarea creada con éxito.',
        update: '!Tarea actualizada correctamente!',
        delete: '!Tarea eliminada correctamente!',
        priority: '!Prioridad actualizada correctamente!',
      },
      submission: {
        create: '!Envío creado con éxito!',
        delete: '!Envío eliminado correctamente!',
      },
      recommendedSubmission: {
        create: '¡Tarea enviada correctamente!',
      },
    },
    error: {
      multipleFilesNotAllowed: 'No se puede agregar más de un archivo',
      task: {
        create:
          '¡Ups, lo sentimos! Un error ocurrió al enviar tu tarea. Por favor, intenta nuevamente.',
        update:
          'Se produjo un error al actualizar la Tarea. Por favor, inténtelo de nuevo más tarde.',
        delete:
          'Se produjo un error al eliminar la Tarea. Por favor, inténtelo de nuevo más tarde.',
        getTaskData:
          'Se produjo un error al obtener los datos de la Tarea. Por favor, inténtelo de nuevo más tarde.',
        effectivenessCheck: {
          idAlreadyAdded: '¡Esta ID ya ha sido agregada!',
        },
        priority:
          'Se produjo un error al actualizar la Prioridad. Por favor, inténtelo de nuevo más tarde.',
        invalidFrequency: 'La frecuencia no es válida, seleccione una de las opciones disponibles.',
        unavailableMonthlyFrequency:
          'Las tareas de cobertura no funcionan, se debe registrar una frecuencia de 30 días en el back-end para este vendorGroupID.',
      },
      submission: {
        create:
          '¡Ups, lo sentimos! Un error ocurrió al enviar su Envío. Por favor, intenta nuevamente.',
        delete:
          'Se produjo un error al eliminar lo Envío. Por favor, inténtelo de nuevo más tarde.',
      },
    },
    warning: {
      task: {
        requiredField:
          'Campos obligatorios no rellenados! Por favor, compruebe el formulario antes de enviarlo de nuevo.',
      },
    },
  },
  comboBox: {
    noOptions: 'No se encontraron opiniones',
  },
  message: {
    desc: {
      deleteTask: 'Si lo haces, todos los envíos relacionados serán eliminiados.',
      deleteSubmission: 'Si lo haces, todos los envíos relacionados serán eliminiados.',
      modalConfirmDefault: '¿Estás seguro de que quieres hacer esto?',
      modalConfirmChangeEffectivenessIDAddMode: {
        toMANUAL: 'Si lo hace, el archivo CSV se borrará definitivamente.',
        toCSV: 'Si lo hace, todas las identificaciones agregadas se borrarán definitivamente..',
      },
      modalConfirmChangeSubCategory:
        'Si lo hace, la información de la Subcategoría seleccionada previamente se borrará de forma permanente.',
      dropzoneCsvIds: 'Coloque un archivo .CSV con los ID o',
      categoryPhoto: '*Para tareas fotográficas, ejecución es la única categoría aceptada.',
      criteriaBdrIdsQuantity: '{quantity} ID de BDR añadidos',
    },
    loading: {
      default: 'Cargando...',
      waitAMoment: 'Espere un momento...',
      validating: 'Validando, espera un momento...',
    },
    success: {
      fileValidate: '¡Archivo validado!',
      priorityUpdated: 'Prioridad actualizada',
      validTaskCategory: 'Categoría de tarea válida seleccionada',
    },
    error: {
      default: 'Ooops! Lo siento, algo salió mal',
      request: 'Se produjo un error con su solicitud. Por favor, inténtelo de nuevo más tarde.',
      notFound: 'Mmm Parece que esta página se ha ido',
      notFoundSuggestion: 'Parece que esta página no se encontró o no existe.',
      userWithoutGroups: 'Su usuario no tiene permisos de los grupos',
      userWithoutVendorId: 'Su usuario no tiene un ID de empresa',
      userWithoutCountry: 'Su usuario no tiene un País',
      userWithoutCountryAndVendor: 'Su usuario no tiene un País y um ID de empresa',
      userWithoutProfile:
        'Se produjo un error al solicitar los datos de su perfil. Por favor verifique si los datos están configurados correctamente.',
      userWithoutTaskGroup:
        'Se produjo un error al solicitar los datos de sus grupos de tareas. Por favor verifique si los datos están configurados correctamente.',
      userWithoutTaskTypes:
        'Se produjo un error al solicitar los datos de sus tipos de tareas. Por favor, compruebe si los datos están configurados correctamente.',
      userWithoutTaskCategories:
        'Se produjo un error al solicitar los datos de sus categorías de tareas. Por favor verifique si los datos están configurados correctamente.',
      requestUserInfo:
        'Se produjo un error con su solicitud de sus datos de usuario. Por favor, inténtelo de nuevo más tarde.',
      getTaskOptions: '¡Perdón! Opciones no encontradas. Inténtelo de nuevo haciendo',
      optimizelyNotLoaded:
        '¡Perdón!. La conexión con el servicio de optimización no ha tenido éxito. inténtelo de nuevo más tarde.',
      withoutResults: '¡Perdon! No se encontraron resultados',
      searchEmptyList: '¡Perdon! No se encontraron resultados para',
      searchEmptyListSuggestion: 'Comprueba que has escrito correctamente las palabras.',
      tasksNotFound: '¡Ups! No hay tareas para enumerar.',
      tasksNotFoundSuggestion: 'Intente cambiar el filtro seleccionado o crear una nueva tarea.',
      vendorUndefinedTaskList: 'Seleccione un país y una empresa disponibles.',
      emptyFile: 'El archivo esta vacio',
      dropzoneEmpty: 'No se han insertado archivos. ',
      dropzoneEmptySuggestion: '¡Intenta insertar algo!',
      invalidCsvTask: 'Inserte un archivo .CSV válido antes de enviar la tarea. ',
      fileWrongExtension: 'Extensión de archivo incorrecta',
      fileMoreThanOneColumn: 'El archivo tiene más de una columna',
      fileWrongColumnName: 'Nombre de columna incorrecto',
      multipleAnswer: 'Hay una tarea / pregunta con menos de dos respuestas definidas',
      duplicatedAnswer: 'Hay una tarea / pregunta con una respuesta duplicada',
      visionJobIdNotValid: 'ERROR: Vision Job ID seleccionado inválido',
      selectVisionJobIdValid: 'Por favor, escriba o pegue otro vision Job ID',
      duplicatedBdrId: 'ID de BDR ya ingresado. Prueba otro.',
      invalidBdrId: 'ID de BDR no encontrado. Entra otro.',
      invalidSku: 'SKU no encontrado. Entra otro.',
      someSkuInvalid:
        'Algunos SKU no son válidos, descargue el informe para obtener información detallada.',
      allSkuInvalid:
        'Todos los SKU enviados no son válidos, descargue el informe para obtener información detallada.',
      operationNotFound: 'Error al recuperar información de la operación.',
      subtypeVolumeNotFound:
        'La tarea de plantilla de cartera no se puede crear porque este vendorGroupId no tiene el subtipo correspondiente al VOLUMEN creado',
    },
    alert: {
      setTaskPriority: 'Establecer la prioridad de esta tarea',
      deprecatedTaskCategory:
        'La categoria de tarea seleccionaa ya no está disponible. Por favor, seleccione otra.',
      reviewUpdates:
        'Su organización envió una nueva actualización. Revise todas las sugerencias de templates nuevos.',
    },
  },
  button: {
    proceed: 'Proceder',
    newTask: 'Nueva tarea',
    newPrioritization: 'Enviar nueva priorización',
    newRecommendTask: 'Nuevo template recomendado',
    recommendedUpdates: 'Actualizaciones recomendadas',
    reviewUpdates: 'Revisar actualizaciones',
    newSubmission: 'Enviar',
    openCancellationRequest: 'Abrir solicitud',
    updateCancellationRequest: 'Solicitud de actualización',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    apply: 'Aplicar',
    saveChanges: 'Guardar cambios',
    tryAgain: 'Intenta de nuevo',
    backToHome: 'Volver a la página de inicio',
    tryAgainTaskOptions: 'clic aquí.',
    goBackTask: 'Volver',
    createTask: 'Crear Tarea',
    saveCreateTask: 'Guardar nueva tarea',
    addAudienceCheck: 'Agregar verificación de audiencia',
    addEffectivenessCheck: 'Agregar condiciones de validación',
    clearSearch: 'Limpiar búsqueda',
    clearSearchFilters: 'Limpiar filtros',
    clearFilters: 'Limpiar todo',
    viewOnlyTask: 'Ver',
    viewAndEditTask: 'Ver y editar',
    duplicateTask: 'Duplicar',
    deleteTask: 'Eliminar',
    deleteSubmission: 'Eliminar envío',
    send: 'Enviar',
    downloadModel: 'Descargar modelo',
    browseFiles: 'Navega en tu dispositivo',
    newQuestion: 'Agregar nueva pregunta',
    tabs: {
      submit: 'Envíos',
      priority: 'Prioridades',
    },
    jumpToQuestion: 'Ir a la pregunta',
    endQuestionary: 'Finalizar el cuestionario',
    addNewAnswer: 'Añadir nueva respuesta',
    addVisionJobId: 'Incluir',
    filter: 'Filtros',
    openRequest: 'Abrir solicitud',
    cancelTask: 'Cancelar tarea',
    downloadCSV: 'Descargar CSV',
    discard: 'Desechar',
    save: 'Guardar',
    downloadReport: 'Descargar Informe',
    addOperation: 'Añadir condición',
    gobacktoedit: 'Volver a editar',
    goToAudienceBuilder: 'Ir al Constructor de Audiencias',
  },
  formField: {
    placeholder: {
      submission: {
        name: 'Ingrese el nombre del envío',
        validity: 'Seleccione el período de validez',
        drop: 'Suelta aquí un archivo .CSV con los Account IDs',
      },
      formulaOperation: {
        selectFeature: 'Seleccionar característica',
        selectcombo: 'Seleccionar combo',
        selectchallenge: 'Seleccionar a desafio',
        selectOperator: 'Seleccionar operador',
        selectCondition: 'Seleccionar condición',
        selectValue: 'Seleccionar valor',
        value: 'Ejemplo: 5',
        selectPeriod: 'Seleccionar el periodo',
        periodNotAvailable: 'Periodo no disponible',
      },
      task: {
        search: 'Buscar tarea',
        name: 'Digita un nombre para la encuesta',
        instruction: 'Escriba una instrucción para la tarea',
        category: 'Seleccione una categoría',
        taskEffective:
          'Ingrese el texto que se mostrará en el historial de tareas cuando la tarea era válida',
        taskPending:
          'Ingrese el texto que se mostrará en el historial de tareas mientras la validación está pendiente',
        taskNotEffective:
          'Ingrese el texto que se mostrará en el historial de tareas cuando se invalide la tarea',
        controlTags: 'Introduzca etiquetas de control. Ejemplo: Campaña, VIP, marcas, etc.',
        optionalText: '(Opcional)',
        estimatedTime: 'Ejemplo: 5',
        selectValue: 'Seleccione una opción',
        taskGroup: 'Seleccione un grupo de tareas',
        simple: {
          audienceCheck: {
            main: 'Define las condiciones que hacen que esta tarea sea válida. Si no agregas ninguna, la tarea se validará automáticamente',
          },
          effectivenessCheck: {
            main: 'Si no se agregan las condiciones de validación, la tarea será validada en el momento en que el {perfil} la complete.',
            id: {
              MONTHLY_PRODUCT_COVERAGE: 'Agregar SKUs de Cobertura',
              COMBO: 'Agregar ID do Combo',
              CHALLENGE: 'Agregar ID do Desafío',
              VOLUME: 'Agregar SKUs de Volumen',
            },
            minimumQuantity: 'Introduzca la cantidad mínima',
          },
          selectModelId: 'Seleccione un ID de modelo local:',
        },
        survey: {
          inputAnswerFreeText: 'Respuesta BDR (Texto libre)',
          inputAnswerNumeric: 'Respuesta BDR (Numérico)',
          questionTitle: 'Escribe la pregunta',
          selectValue: 'Seleccione un valor',
          optionValue: 'introduzca la respuesta',
          selectCondition: 'Seleccionar condición',
        },
        photo: {
          inputVisionJobId: 'Introduzca o pegue el Vision Job ID',
          visionJobIdSelected: 'Vision Job ID seleccionado',
        },
      },
      sponsored: {
        search: 'Seleccione una opción o escriba para buscar',
      },
    },
    label: {
      submission: {
        name: 'Nombre de envío',
        validity: 'Validez',
        audienceFilters: 'Filtros de audiencia',
        inclusionList: 'Lista de inclusión',
        exclusionList: 'Lista de exclusión',
      },
      recommendedSubmission: {
        destinationGroup: 'Grupo de destino',
        destinationGroupHint: 'Vendedores elegibles para recibir esta tarea.',
      },
      formulaOperation: {
        selectOperator: {
          equalsToLabel: 'Igual a',
          greaterThanLabel: 'Más grande que',
          greaterOrEqualsToLabel: 'Más grande o igual a',
          lessThanLabel: 'Menor que',
          lessOrEqualsToLabel: 'Menor o igual a',
          label: 'Operador',
        },
        selectCondition: {
          andLabel: 'Y',
          orLabel: 'O',
        },
        combo: 'Combo',
        challenge: 'Desafío',
        selectFeature: 'Característica',
        products: 'Productos',
        product: 'Producto',
        selectProducts: 'Seleccionar productos',
        quantityIndicatorIntlText: 'de',
        selectValue: 'Valor',
        selectPeriod: {
          label: 'Periodo',
        },
      },
      task: {
        sponsor: 'Socio',
        name: 'Nombre de la tarea',
        taskNameHint: 'Este nombre se mostrará en el portal de administración de BEES Force.',
        instruction: 'Instrucción de tarea',
        category: 'Categoría de tarea',
        subCategory: 'Elige una subcategoría:',
        categoryHint: 'El área de negocio en la que esta tarea debería tener impacto',
        taskEffective: 'Texto de tarea validado',
        taskPending: 'Texto de tarea pendientes',
        taskNotEffective: 'Texto de tarea no válido',
        globalTask: 'Tarea global',
        controlTags: 'Etiquetas de control',
        estimatedTime: 'Tiempo estimado de tarea',
        frequencySelector: 'Frecuencia de tarea',
        taskGroup: 'Grupo de tareas',
        taskGroupHint: 'El grupo al que se asignará la tarea para que se active',
        instructionHint: 'Esta descripción se mostrará al BDR en la aplicación BEES Force',
        list: {
          selectProfileListTasks: 'Perfil:',
          selectOperation: 'Operación',
          selectOperationTooltip: 'La operación incluye a la organización y al vendedor.',
          selectTaskType: {
            label: 'TIPO DE TAREA:',
            sponsored: 'Patrocinada',
            nonSponsored: 'No Patrocinada',
          },
        },
        create: {
          selectProfile: '¿Quién realizará esta tarea?',
          selectTaskType: '¿Qué tipo de tarea quieres crear?',
        },
        simple: {
          duration: 'Seleccione una estimación de tiempo para completar la tarea:',
          frequency:
            'Seleccione una frecuencia que esta tarea debería estar disponible para el BDR:',
          audienceCheck: 'Audiencia',
          audienceLabel: 'Define a qué POCs se dirige esta tarea.',
          audienceInfo: 'Incluir POCs',
          effectivenessCheck: 'Criterio de eficacia',
          effectivenessId: {
            CHALLENGE: 'Agregar ID do Desafío',
            COMBO: 'Agregar ID do Combo',
            MONTHLY_PRODUCT_COVERAGE: 'SKU para validación',
            VOLUME: 'SKU para validación',
          },
          minimumQuantity: 'Cantidad mínima',
          scheduleAndFrequency: {
            title: 'Programación y frecuencia',
            subtitle:
              'Definir la duración de la tarea, la frecuencia y el tiempo estimado de finalización.',
            toggle: {
              title: 'Habilitar descuento de tiempo',
              subtitle:
                'Cuando está habilitado, el tiempo dedicado a esta tarea se descontará del tiempo total de visita.',
              description: 'Habilitado',
            },
            info: 'Considere el tiempo total de la visita al establecer el tiempo de la tarea. Esto ayuda al algoritmo a distribuir las tareas de manera efectiva entre los BDR, asegurándose de que todos tengan suficiente tiempo para completar sus tareas.',
          },
          selectModelId: 'Seleccione un ID de modelo local:',
        },
        sponsored: 'Tarea patrocinada',
        sponsoredPartnerLabel: 'Socio',
        sponsoredPartnerParagraph: 'Seleccione un socio para patrocinar esta tarea.',

        survey: {
          questionary: {
            questionTitle: 'Pregunta número',
          },
        },
        photo: {
          inputVisionJobId: 'Incluir Vision Job ID',
        },
      },
    },
    value: {
      task: {
        type: {
          SIMPLE: 'Estándar',
          SURVEY: 'Encuesta',
          IMAGE_RECOGNITION: 'Foto',
        },
        category: {
          CUSTOMER_SATISFACTION: 'Satisfacción del Cliente',
          DIGITAL_EDUCATION: 'Educación Digital',
          EXECUTION: 'Ejecución',
          VALUE_CREATION: 'Creación de Valor',
          PORTFOLIO_EXPANSION: 'Expansión de Portfolio',
          VOLUME_REVENUE: 'Volumen y Facturación',
          MARKETPLACE_PORTFOLIO_EXPANSION: 'Expansión de Portfolio Marketplace',
          MARKETPLACE_REVENUE: 'Facturación Marketplace',
          EXECUTION_ABI: 'Ejecución ABI',
          EXECUTION_MARKETPLACE: 'Ejecución Marketplace',
          MI_NEGOCIO: 'Mi Negocio',
          FINTECH: 'FinTech',
          CHALLENGE_REWARDS: 'Desafíos y Recompensas',
          ORDER_BEES: 'Ordenes en BEES',
          DTAAS: 'DTaaS',
          SURVEY_DATA_COLLECTION: 'Encuesta/Recopilación de Datos',
          OTHER_TASKS: 'Otras Tareas',
        },
        subCategory: {
          CHALLENGE: 'Desafío',
          COMBO: 'Combo',
          MONTHLY_PRODUCT_COVERAGE: 'Cobertura',
          SHELF: 'Estantes',
          COOLER: 'Refrigeradores',
          POSTER: 'Poster',
          VOLUME: 'Volumen',
        },
        feature: {
          account_status: 'Estado de la cuenta (activo o bloqueado)',
          sub_segment: 'Subsegmento (texto)',
          channel: 'Canal (texto)',
          potential: 'Potencial (texto)',
          is_key_account: 'Contraseña (verdadero o falso)',
          has_overprice: 'Precio excesivo (verdadero o falso)',
          has_empty_loan: 'Préstamo vacío (verdadero o falso)',
          segment: 'Segmento POC (texto)',
          days_last_order: 'Días desde el último pedido del Mercado (número)',
          qty_skus_mp_l15d: 'SKU de Marketplace pedidos en los últimos 15 días (número)',
          qty_skus_mp_l30d: 'SKU de Marketplace pedidos en los últimos 30 días (número)',
          qty_skus_mp_l60d: 'SKU de Marketplace pedidos (número)',
          qty_skus_mp_last_order: 'SKU de Marketplace en el último pedido (número)',
          avg_skus_mp_per_order_l30d:
            'SKU promedio de Marketplace por pedido durante los últimos 30 días (número)',
          mdn_skus_mp_per_order_l30d:
            'SKU de mercado promedio por pedido durante los últimos 30 días (número)',
          mkp_revenue_l30d: 'Ingresos del mercado en los últimos 30 días (número)',
          mkp_revenue_l60d: 'Ingresos del mercado',
          qty_digital_orders_l30d: 'Pedidos digitales en los últimos 30 días (número)',
          qty_digital_orders_l60d: 'Pedidos digitales',
          non_digital_revenue_l30d: 'Ingresos no digitales en los últimos 30 días (número)',
          digital_revenue_l30d: 'Ingresos netos digitales en los últimos 30 días (número)',
          total_revenue_l30d: 'Ingresos netos totales en los últimos 30 días (número)',
        },

        feature_value: {
          true: 'Verdadero',
          false: 'Falso',
          active: 'Activo',
          blocked: 'Bloqueado',
        },
        period: {
          l60d: 'Últimos 60 días',
          l30d: 'Últimos 30 días',
          l15d: 'Últimos 15 días',
          tmth: 'Este mes',
          lmth: 'Último mes',
        },
        simple: {
          frequency: {
            daily: 'En cada visita',
            weekly: 'Semanalmente',
            everyVisit: 'En cada visita',
            monthly: 'Cada 30 días',
            once_a_month: 'Una vez al mes',
          },
        },
        survey: {
          responseType: {
            numeric: 'Numérico',
            freeText: 'Texto libre',
            checkBox: 'Selección múltiple',
            radioButton: 'Selección única',
            conditional: 'Condicional',
          },
        },
      },
      selectProfile: {
        bdr: 'BDR',
        merchandiser: 'Merchandiser',
        negbdr: 'BDR Negotiation',
        business_executive: 'Business Executive',
        brfbdr: 'BDR BRF',
      },
    },
    tip: {
      task: {
        type: {
          SIMPLE:
            'La tarea estándar es una acción para que los BDRs ejecuten durante la visita al PDV. Este tipo de tarea consiste en un título y una instrucción.',
          SURVEY:
            'La encuesta consiste en varias preguntas para que los BDRs respondan durante la visita al PDV. Las preguntas pueden tener diferentes tipos de respuestas, como opciones múltiples, texto libre, numérico o Sí o No.',
          IMAGE_RECOGNITION:
            'El tipo de tarea fotográfica permite a los BDR registrar fácilmente los activos de PDV como refrigeradores o materiales del mercado comercial mediante el uso de la cámara incorporada en sus dispositivos. El tipo de tarea fotográfica también está conectado a la inteligencia de reconocimiento de imágenes que permite automatizar la operación de verificación de efectividad.',
        },
        simple: {
          frequency: {
            everyVisit:
              'En cada visita: La tarea estará disponible en cada visita al POC. Con todo, esta plantilla de tarea se puede reemplazar por otra tarea con mayor prioridad.',
            monthly:
              'Cada 30 días: La tarea estará disponible para BDR dentro de los 30 días entre visitas.',
          },
        },
      },
    },
    button: {
      changeDefaultMessages: 'Editar mensajes de validación de tareas predeterminados',
    },
    defaultValues: {
      validEffectiveness: '¡Tarea completada! ¡Buen trabajo!',
      invalidEffectiveness: 'La tarea no fue completada.',
      pendingEffectiveness: 'La validación de la tarea aún está pendiente.',
    },
    error: {
      requiredField: 'Este campo es obligatorio',
      minValue: 'Por favor, introduce un valor mayor o igual que {value}',
      maxValue: 'Por favor, introduzca un valor menor o igual que {value}',
      lessOperator: 'Ingrese un valor mayor que {value}',
      greaterOperator: 'Ingrese un valor menor que {value}',
      greaterZero: 'Por favor, ingrese un valor mayor que 0',
      task: {
        requiredDuration: 'Seleccione una opción de estimación para la tarea antes de guardar',
        requiredFrequency: 'Por favor, elija una frecuencia antes de guardar',
        requiredCategory: 'Por favor, elija una categoría de tarea antes de guardar',
        requiredSubCategory: 'Por favor, elija una subcategoría antes de guardar',
        requiredEffectivenessIDs: 'Por favor, agregue los IDs antes de guardar',
        requiredMinimumQuantity: 'Por favor, ingrese la cantidad mínima antes de guardar',
        requiredSponsoredBy: 'Por favor, ingrese al socio antes de salvar',
        addVisionJobId: 'Para incluir el visionjobid seleccionado, haga clic en el botón incluir',
        localModelIdListEmpty:
          'No hay modelIDs válidos configurados para este grupo de proveedores',
      },
    },
    hint: {
      task: {
        inMinutes: '(En minutos)',
        simple: {
          effectivenessCheck: {
            minimumQuantity: 'Introduzca un valor hasta 9999',
          },
          inputAnalyticTags:
            'Use comas para separar los valores y los espacios serán reemplazados por guiones.',
        },
      },
    },
  },
  submission: {
    noneSubmission: 'Ninguno activo',
    status: {
      FINISHED: 'Terminado',
      SCHEDULED: 'Programado',
      ACTIVE: 'Activo',
      CONCLUDED: 'Terminado',
      INVALID: 'Inválido',
      INVALIDS: 'Inválido',
      INPROGRESS: 'Procesando',
      IN_PROGRESS: 'Procesando',
      PENDING: 'Pendiente',
      FINALIZED: 'Finalizado',
      SUBMITTED: 'Enviado',
    },
    list: {
      table: {
        taskSubmissions: 'Envíos de tareas',
        status: 'Status',
        validity: 'Validez',
      },
      ddateFormat: 'dd/MM/yyyy',
      noValidity: 'Sin validez',
    },
    submit: {
      messages: {
        error: {
          finishedErrorAccountFoundDifferent:
            'Finalizó con errores, se procesaron {accountFound} de {accountSize}',
          finishedErrorAccountDuplicated:
            'Finalizó con {accountDuplicated} de {accountSize} duplicados, procesaron {accountSubmitted}',
          finishedErrorAccountInvalidAccountDuplicated:
            '{accountNotFound} inválidos, {accountDuplicated} duplicados, procesaron {accountSubmitted}',
          invalidItems: 'Error de archivo CSV, elementos no válidos',
        },
        inProgress: {
          default: 'Cargando CSV: {submissionProgress}',
        },
        success: {
          default: 'CSV procesado con éxito',
        },
      },
    },
  },
  csv: {
    header: {
      accountsId: 'accountId',
      accountsJustified: 'justificado',
      accountsAnswered: 'contestada',
      accountsNotAnswered: 'no contestado',
      accountsCancelled: 'cancelado',
      accountsCreated: 'creado',
    },
    content: {
      answerYes: 'sí',
      answerNo: 'no',
    },
    footer: {
      total: 'total',
    },
  },
  pagination: {
    pageSize: 'Mostrar {pageSize} elementos por página',
  },
  emptyState: {
    title: {
      noRequests: 'Aún no hay solicitudes',
    },
    desc: {
      noRequests: 'Tus solicitudes futuras se mostrarán aquí.',
    },
  },
  app: {
    taskGroups: { ...taskGroupsEs419 },
  },
  configureOperationModal: {
    title: 'Editar operación',
    description: 'Configura la página de tareas para reflejar lo siguiente:',
    cancel: 'Cancelar',
    confirm: 'Guardar',
    titleCountryInput: 'País',
    countryPlaceholder: 'Seleccione una opción',
    titleOrganizationInput: 'Organización',
    subTitleOrganizationInput: 'Puede incluir empresas y país.',
    organizationPlaceholder: 'Selecciona una organización',
    titleSellerInput: 'Vendedor',
    subTitleSellerInput: 'Quien recibirá la tarea.',
    sellerPlaceholder: 'Selecciona un vendedor',
  },
  drawerComponent: {
    title: 'Seleccionar productos',
    search: 'Buscar por nombre o ID del SKU',
    emptyMessage: 'Busca los productos que deseas incluir en tu variable.',
    emptyMessageFiltered:
      'Verifica la ortografía o prueba con palabras clave diferentes. Es posible que los SKU ya estén agregados.',
    cancelButton: 'Cancelar',
    addSelectedButton: 'Agregar SKU seleccionado',
    noResultsFound: 'No se encontraron resultados',
    nestedTitle: 'SKUs seleccionados',
    nestedRemoveAll: 'Eliminar todo',
    nestedSkusSelected: '{totalSelected} SKUs selecionados',
    finishSelection: 'Finalizar selección',
  },
  drawerComponentTaskList: {
    detailsTabTitle: 'Detalles',
    detailsCancelBtn: 'Rechazar',
    detailsUpdateBtn: 'Aceptar',
    btnShowReviewedItems: 'Mostrar artículos revisados',
    btnReview: 'Revisar',
    btnChangeReview: 'Revisar nuevamente',
  },
  audienceSelect: {
    title: {
      dynamic: 'Seleccionar filtros',
      static: 'Seleccionar lista de POCs',
    },
    subTitle: {
      dynamic:
        'Establezca parámetros para definir los POCs que deberían verse afectados por esta tarea.',
      static:
        'Seleccione una lista de POCs predefinida o cargue una nueva en Constructor de Audiencias.',
    },
    fields: {
      label: {
        vendor: 'Vendedor',
      },
      placeholder: {
        vendor: 'Seleccione una opción',
      },
    },
    info: {
      audienceStaticInfo:
        'Debes ir al Constructor de Audiencias para subir una nueva lista de POCs. Procesar una lista puede tardar algunas horas.',
    },
    error: {
      audienceType: 'Se requiere selección para continuar',
    },
    btnAddStaticAudience: 'Añadir audiencia',
  },
};

export default es419;
