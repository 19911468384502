// istanbul ignore file
import { Button, Dialog, Heading, Paragraph, Select } from '@hexa-ui/components';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useGroupData from '../../hooks/useContext/useGroupData/useGroupData';
import { GetGroupDetails } from '../../models/group';
import { ConfigureOperationModalProps } from './ConfigureOperationModal.d';
import Styled from './ConfigureOperationModal.styles';
import { useConfigureOperationModal } from './hooks/useConfigureOperationModal';

const ConfigureOperationModal = ({
  isOpen,
  onClose,
  onCancel,
}: ConfigureOperationModalProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { groupData, countriesList, countrySelected: countrySelectedBefore } = useGroupData();
  const { organization, seller } = groupData;

  const [countrySelected, setCountrySelected] = useState<{
    label: string;
    value: string;
  } | null>(countrySelectedBefore);
  const [selectedOrganization, setSelectedOrganization] = useState<GetGroupDetails | null>(
    organization
  );
  const [selectedSeller, setSelectedSeller] = useState<GetGroupDetails | null>(seller);

  const {
    organizations,
    hasNoOperationsAvailable,
    isSaveButtonDisable,
    isCountrySelectorDisabled,
    sellers,
    onConfirmModal,
  } = useConfigureOperationModal({
    countrySelected: countrySelected.value,
    selectedSeller,
    selectedOrganization,
    onClose,
  });

  return (
    <div>
      {isOpen && (
        <Dialog.Root
          title={
            <Heading data-testid="configureOperationModal" size="H2">
              {formatMessage({ id: 'configureOperationModal.title' })}
            </Heading>
          }
          onClose={() => {
            onClose();
          }}
          contentCss={{ overflowY: 'visible' }}
          open={isOpen}
          closeButton={true}
          actions={
            <Styled.WrapperDialogButtons>
              <Dialog.Close>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  {formatMessage({ id: 'configureOperationModal.cancel' })}
                </Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button
                  data-testid="configureOperationModalConfirmButton"
                  disabled={isSaveButtonDisable ?? hasNoOperationsAvailable}
                  onClick={onConfirmModal}
                >
                  {formatMessage({ id: 'configureOperationModal.confirm' })}
                </Button>
              </Dialog.Close>
            </Styled.WrapperDialogButtons>
          }
        >
          <Styled.BodyContainer>
            <Paragraph css={{ marginBottom: '26px' }}>
              {formatMessage({ id: 'configureOperationModal.description' })}
            </Paragraph>

            <Styled.InputContainer>
              <Select.Root
                data-testid="configureOperationModalCountryInput"
                label={formatMessage({ id: 'configureOperationModal.titleCountryInput' })}
                value={countrySelected?.value}
                placeholder={
                  isCountrySelectorDisabled
                    ? countrySelected.label
                    : formatMessage({ id: 'configureOperationModal.countryPlaceholder' })
                }
                disabled={isCountrySelectorDisabled}
                onChange={(item: string) => {
                  setCountrySelected(countriesList.find((c) => c.value === item));
                  setSelectedOrganization(null);
                  setSelectedSeller(null);
                }}
              >
                {countriesList.map((country) => (
                  <Select.Option
                    key={`countryOption-${country.value}`}
                    data-testid={`countryOption-${country.value}`}
                    value={country.value}
                  >
                    {country.label}
                  </Select.Option>
                ))}
              </Select.Root>

              {countrySelected && (
                <Select.Root
                  data-testid="configureOperationModalOrganizationInput"
                  label={formatMessage({ id: 'configureOperationModal.titleOrganizationInput' })}
                  hint={formatMessage({ id: 'configureOperationModal.subTitleOrganizationInput' })}
                  placeholder={formatMessage({
                    id: 'configureOperationModal.organizationPlaceholder',
                  })}
                  value={selectedOrganization?.id}
                  disabled={hasNoOperationsAvailable}
                  onChange={(id) => {
                    const selectedItem = organizations.find((item) => item.id === id);
                    setSelectedOrganization(selectedItem);
                    setSelectedSeller(null);
                  }}
                >
                  {organizations.map((organization) => (
                    <Select.Option
                      key={`organizationOption-${organization.id}`}
                      data-testid={`organizationOption-${organization.id}`}
                      value={organization.id}
                    >
                      {organization.name}
                    </Select.Option>
                  ))}
                </Select.Root>
              )}

              {selectedOrganization?.childGroupIds?.length > 0 && (
                <Select.Root
                  data-testid="configureOperationModalSellerInput"
                  label={formatMessage({ id: 'configureOperationModal.titleSellerInput' })}
                  hint={formatMessage({ id: 'configureOperationModal.subTitleSellerInput' })}
                  placeholder={formatMessage({ id: 'configureOperationModal.sellerPlaceholder' })}
                  value={selectedSeller?.id}
                  disabled={hasNoOperationsAvailable}
                  onChange={(id) => {
                    const selectedSeller = selectedOrganization.childGroups.find(
                      (item) => item.id === id
                    );
                    setSelectedSeller(selectedSeller);
                  }}
                >
                  {sellers.map((seller) => (
                    <Select.Option
                      key={`sellerOption-${seller.id}`}
                      data-testid={`sellerOption-${seller.id}`}
                      value={seller.id}
                    >
                      {seller.name}
                    </Select.Option>
                  ))}
                </Select.Root>
              )}
            </Styled.InputContainer>
          </Styled.BodyContainer>
        </Dialog.Root>
      )}
    </div>
  );
};

export default ConfigureOperationModal;
